import './App.css';

import AppRoute from './RouteComponent';
import Main from './componentes/Main/Main';

function App() {
  return (
    <div>
      <AppRoute/>
   </div>
  );
}

export default App;
