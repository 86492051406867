import './Footer.css'
import React from 'react'

export const Footer=() => {
    return (
        
        <footer className='footer'>
            <div className='img-container3'>
                <a href=''>
                    <img src='/img/comidavddbranco.png' className='img'/>
                </a>
            </div>
    </footer>
    )

}
